var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_vm.isPaying ? [_c('c-text', {
    attrs: {
      "font-size": "xl",
      "color": "primary.400"
    }
  }, [_vm._v(" PROGRAM ")]), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "w": "full"
    }
  }), _c('c-form-control', {
    attrs: {
      "mt": "20px",
      "is-required": "",
      "is-invalid": _vm.$v.selectedProgramId.$invalid
    }
  }, [_c('c-form-label', [_vm._v("Program")]), _c('c-select', {
    attrs: {
      "h": "62px",
      "placeholder": "Pilih Program"
    },
    model: {
      value: _vm.selectedProgramId,
      callback: function callback($$v) {
        _vm.selectedProgramId = $$v;
      },
      expression: "selectedProgramId"
    }
  }, _vm._l(_vm.programs, function (program) {
    return _c('option', {
      key: program.id,
      domProps: {
        "value": program.id
      }
    }, [_vm._v(" " + _vm._s(program.name) + " ")]);
  }), 0)], 1), _c('c-form-control', {
    attrs: {
      "mt": "20px",
      "is-required": "",
      "is-invalid": _vm.$v.selectedServiceId.$invalid
    }
  }, [_c('c-form-label', [_vm._v("Layanan")]), _c('c-select', {
    attrs: {
      "h": "62px",
      "placeholder": "Pilih Layanan"
    },
    model: {
      value: _vm.selectedServiceId,
      callback: function callback($$v) {
        _vm.selectedServiceId = $$v;
      },
      expression: "selectedServiceId"
    }
  }, _vm._l(_vm.services, function (service) {
    return _c('option', {
      key: service.id,
      domProps: {
        "value": service.id
      }
    }, [_vm._v(" " + _vm._s(service.programsService) + " ")]);
  }), 0)], 1)] : _vm._e(), _vm.isPaying ? _c('c-box', {
    attrs: {
      "mt": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "xl",
      "color": "primary.400"
    }
  }, [_vm._v(" BIODATA ")]), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "w": "full"
    }
  })], 1) : _vm._e(), _c('c-flex', {
    attrs: {
      "mt": _vm.isPaying ? ['20px'] : [],
      "flex-dir": "column"
    }
  }, [_c('c-stack', {
    attrs: {
      "is-inline": "",
      "mt": "10px",
      "align-items": "center",
      "spacing": "10px"
    }
  }, [_c('c-box', {
    attrs: {
      "rounded": "full",
      "pos": "relative",
      "h": "180px",
      "w": "180px"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "pos": "absolute",
      "top": "0",
      "left": "0",
      "w": "100%",
      "h": "100%",
      "bg": "#00000088",
      "rounded": "full",
      "p": "0",
      "_hover": {
        bg: '#00000055'
      }
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.$inputFile.click();
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-pencil.svg'),
      "size": "55px"
    }
  })], 1), _c('img', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "src": _vm.photoUrl,
      "h": "180px",
      "w": "180px",
      "object-fit": "cover",
      "rounded": "full"
    }
  }), _c('input', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    ref: "$inputFile",
    attrs: {
      "type": "file",
      "accept": "image/*",
      "d": "none"
    },
    on: {
      "input": function input($event) {
        return _vm.onChangeFile($event);
      }
    }
  })], 1), _c('c-text', {
    attrs: {
      "font-size": "md",
      "font-weight": "300",
      "color": "#888"
    }
  }, [_vm._v(" Foto maximal 3 MB ")])], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": _vm.requiredFields_.includes('firstName'),
      "is-invalid": _vm.$v.firstName.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Nama Depan")]), _c('c-input', {
    attrs: {
      "h": "62px",
      "type": "text",
      "name": "firstName"
    },
    model: {
      value: _vm.firstName,
      callback: function callback($$v) {
        _vm.firstName = $$v;
      },
      expression: "firstName"
    }
  })], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": _vm.requiredFields_.includes('lastName'),
      "is-invalid": _vm.$v.lastName.$invalid,
      "d": "",
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Nama Belakang")]), _c('c-input', {
    attrs: {
      "h": "62px",
      "type": "text",
      "name": "lastName"
    },
    model: {
      value: _vm.lastName,
      callback: function callback($$v) {
        _vm.lastName = $$v;
      },
      expression: "lastName"
    }
  })], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": _vm.requiredFields_.includes('address'),
      "is-invalid": _vm.$v.address.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Alamat")]), _c('c-input', {
    attrs: {
      "h": "62px",
      "type": "text",
      "name": "address"
    },
    model: {
      value: _vm.address,
      callback: function callback($$v) {
        _vm.address = $$v;
      },
      expression: "address"
    }
  })], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": _vm.requiredFields_.includes('birthDate'),
      "is-invalid": _vm.$v.birthDate.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Tanggal Lahir")]), _c('c-input', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '::-webkit-calendar-picker-indicator': {
          background: "url(".concat(require('@/assets/icon-calendar-event.svg'), ") no-repeat center"),
          cursor: 'pointer'
        }
      },
      expression: "{\n          '::-webkit-calendar-picker-indicator': {\n            background: `url(${require('@/assets/icon-calendar-event.svg')}) no-repeat center`,\n            cursor: 'pointer',\n          },\n        }"
    }],
    attrs: {
      "h": "62px",
      "type": "date",
      "name": "birthDate"
    },
    model: {
      value: _vm.birthDate,
      callback: function callback($$v) {
        _vm.birthDate = $$v;
      },
      expression: "birthDate"
    }
  })], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": _vm.requiredFields_.includes('country'),
      "is-invalid": _vm.$v.country.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Negara")]), _c('c-select', {
    attrs: {
      "h": "62px",
      "name": "country",
      "placeholder": "Negara"
    },
    model: {
      value: _vm.country,
      callback: function callback($$v) {
        _vm.country = $$v;
      },
      expression: "country"
    }
  }, _vm._l(_vm.countries, function (item) {
    return _c('option', {
      key: item.value,
      domProps: {
        "value": item.value
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0)], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": _vm.requiredFields_.includes('province'),
      "is-invalid": _vm.$v.province.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Provinsi")]), _c('c-select', {
    attrs: {
      "h": "62px",
      "name": "province",
      "placeholder": "Provinsi"
    },
    model: {
      value: _vm.province,
      callback: function callback($$v) {
        _vm.province = $$v;
      },
      expression: "province"
    }
  }, _vm._l(_vm.provinces, function (item) {
    return _c('option', {
      key: item.value,
      domProps: {
        "value": item.value
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0)], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": _vm.requiredFields_.includes('city'),
      "is-invalid": _vm.$v.city.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Kota")]), _c('c-select', {
    attrs: {
      "h": "62px",
      "name": "city",
      "placeholder": "Kota"
    },
    model: {
      value: _vm.city,
      callback: function callback($$v) {
        _vm.city = $$v;
      },
      expression: "city"
    }
  }, _vm._l(_vm.cities, function (item, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": item.value
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0)], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": _vm.requiredFields_.includes('zipCode'),
      "is-invalid": _vm.$v.zipCode.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Kode Pos")]), _c('c-input', {
    attrs: {
      "h": "62px",
      "name": "zipCode",
      "type": "number"
    },
    model: {
      value: _vm.zipCode,
      callback: function callback($$v) {
        _vm.zipCode = $$v;
      },
      expression: "zipCode"
    }
  })], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": _vm.requiredFields_.includes('phone'),
      "is-invalid": _vm.$v.phone.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("No. Telepon")]), _c('c-input', {
    attrs: {
      "h": "62px",
      "name": "phone",
      "type": "tel"
    },
    model: {
      value: _vm.phone,
      callback: function callback($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  })], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": _vm.requiredFields_.includes('gender'),
      "is-invalid": _vm.$v.gender.$invalid,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Jenis Kelamin")]), _c('c-select', {
    attrs: {
      "h": "62px",
      "name": "gender",
      "type": "string"
    },
    model: {
      value: _vm.gender,
      callback: function callback($$v) {
        _vm.gender = $$v;
      },
      expression: "gender"
    }
  }, [_c('option', {
    attrs: {
      "value": "female"
    }
  }, [_vm._v(" Perempuan ")]), _c('option', {
    attrs: {
      "value": "male"
    }
  }, [_vm._v(" Laki-Laki ")])])], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": _vm.requiredFields_.includes('email'),
      "is-invalid": _vm.$v.email.$invalid,
      "is-disabled": _vm.isEdit,
      "w": "590px"
    }
  }, [_c('c-form-label', [_vm._v("Email")]), _c('c-input', {
    attrs: {
      "h": "62px",
      "name": "email",
      "type": "string"
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  })], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }